<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card class="mx-auto pt-2" max-width="500px" elevation="0">
        <v-card-title class="mt-2 justify-center py-0">
          <div class="text-h1">404</div>
        </v-card-title>
        <v-card-title class="mt-2 justify-center py-0">
          <div class="text-h5">Pagina no encontrada</div>
        </v-card-title>
        <v-divider class="my-3"></v-divider>
        <v-card-actions class="justify-center mt-0 pt-0">
          <v-btn block :to="{ name: 'Dashboard' }"> Ir al inicio</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NotFound",
  components: {},
};
</script>