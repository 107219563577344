<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card class="mx-auto pt-2" max-width="500px" elevation="0">
        <v-card-title class="mt-2 justify-center py-0">
          <v-icon x-large color="red">mdi-shield-lock</v-icon>
        </v-card-title>
        <v-card-title class="mt-2 justify-center py-0">
          <div class="text-h5">No tiene privilegios para acceder.</div>
        </v-card-title>
        <v-divider class="my-3"></v-divider>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Unauthorized",
  components: {},
};
</script>